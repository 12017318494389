import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, SecondaryCallout} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import nsclc2LmonoSafetyTable from "../../../assets/nsclc-2-l-mono-safety-small-v-2.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions for Second-line or Greater Monotherapy for mNSCLC | HCP`,
    keywords: `keytruda adverse reactions mnsclc second-line or greater monotherapy therapy`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions associated with the KEYNOTE-010 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-second-line-monotherapy/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE⁠-⁠010","description":"In KEYNOTE⁠-⁠010, KEYTRUDA monotherapy was discontinued due to adverse reactions in 8% of 682 patients with metastatic NSCLC; the most common was pneumonitis (1.8%). The most common adverse reactions (≥20%) were decreased appetite (25%), fatigue (25%), dyspnea (23%), and nausea (20%)."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-second-line-monotherapy/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const lung2LSafetyAlt = "Selected Adverse Reactions Occurring in ≥10% of Patients Treated With KEYTRUDA® (pembrolizumab) and at the Same or Higher Incidence Than in the Docetaxel Arm in KEYNOTE010"

//from /src/util/data/indications.js
const indicationId = 7;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: '1L Combo Therapy – Squamous', url: '/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy/' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/safety/adverse-reactions/nsclc-first-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/safety/adverse-reactions/nsclc-neoadjuvant-therapy/' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/safety/adverse-reactions/nsclc-adjuvant-therapy/' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;010'} 
]

const nsclc2LmonoNsqDataTableFootnotes = [
    { 
        label: 'c.',
        text: 'Adverse reactions occurring at same or higher incidence than in docetaxel arm.'
    },
    { 
        label: 'd.',
        text: 'Graded per NCI-CTCAE v4.0.'
    },
    { 
        label: 'e.',
        text: 'Includes rash, rash erythematous, rash macular, rash maculopapular, rash papular, and rash pruritic.'
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>The adverse reaction profile was similar for the 2&nbsp;mg/kg and&nbsp;10 mg/kg doses of KEYTRUDA; therefore, summary safety results are provided in a pooled analysis (n=682).</p>
                                <p>KEYTRUDA 2&nbsp;mg/kg or 10&nbsp;mg/kg Q3W was discontinued due to adverse reactions in 8% of 682 patients with mNSCLC. The most common adverse event resulting in permanent discontinuation of KEYTRUDA was pneumonitis (1.8%).</p>
                            </TextBlock>
                            <SecondaryCallout 
                                title="92% of patients remained on treatment with KEYTRUDA without discontinuation due to adverse reactions"
                                alt=""    
                            />
                            <DataTable
                                title='Selected<sup>c</sup> Adverse Reactions Occurring in ≥10% of Patients Treated With KEYTRUDA and at the Same or Higher Incidence Than in the Docetaxel Arm'
                                footnotes={nsclc2LmonoNsqDataTableFootnotes}
                                additionalInformation={[
                                    {
                                        text: `
                                        <p>Other clinically important adverse reactions occurring in patients receiving KEYTRUDA were fatigue (25%), diarrhea (14%), asthenia (11%), and pyrexia (11%).</p>`},
                                ]}
                                mobileImage={() => <img src={nsclc2LmonoSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={lung2LSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/nsclc-2-l-mono-safety-large-v-1.png" alt={lung2LSafetyAlt}/>}
                            />                            
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
